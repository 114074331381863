import React, { useState, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { css } from '@emotion/css';

const SpSubMenu = ({ items, path, open }) => {
  const [lebel2Open, setLebel2Open] = useState(0);
  const [lebel3Open, setLebel3Open] = useState(0);

  const handleLebel2 = (index) => {
    setLebel2Open(lebel2Open === index ? 0 : index);
  };

  const handleLebel3 = (index) => {
    setLebel3Open(lebel3Open === index ? 0 : index);
  };

  const lebel1Menu = useRef(null);

  const styles = {
    sub: css({

      '@media (min-width: 768px)': {
        display: 'none',
      },
    }),

    lebel1: css({
      overflow: 'hidden',
      backgroundColor: '#395D88',
      transition: 'height .25s',
    }),

    lebel1Item: css({
      borderBottom: '1px solid #AFC3DB',
    }),

    lebel1Btn: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',
      backgroundColor: 'transparent',

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel1Link: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel2: css({
      overflow: 'hidden',
      backgroundColor: '#102C4E',
    }),

    lebel2Item: css({
      borderBottom: '1px solid #AFC3DB',
    }),

    lebel2Btn: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',
      backgroundColor: 'transparent',

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel2Link: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel3: css({
      backgroundColor: '#6889B1',
    }),

    lebel3Item: css({
      borderBottom: '1px solid #AFC3DB',
    }),

    lebel3Link: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),
  };

  return (
    <div className={styles.sub}>
      <AnimateHeight
        duration={250}
        height={open ? 'auto' : 0}
      >
        <ul className={styles.lebel1} ref={lebel1Menu}>
          {
            items.map((item1, index1) => {
              if (!item1.title) return null;
              if (item1.list) {
                return (
                  <li className={styles.lebel1Item} key={index1}>
                    <button className={styles.lebel1Btn} type="button" onTouchStart={() => handleLebel2(index1)}>{item1.title}</button>
                    <AnimateHeight
                      duration={250}
                      height={lebel2Open === index1 ? 'auto' : 0}
                    >
                      <ul className={styles.lebel2}>
                        {
                          item1.list.map((item2, index2) => {
                            if (!item2.title) return null;
                            if (item2.list) {
                              return (
                                <li className={styles.lebel2Item} key={index2}>
                                  <button className={styles.lebel2Btn} type="button" onTouchStart={() => handleLebel3(index2)}>{item2.title}</button>
                                  <AnimateHeight
                                    duration={250}
                                    height={lebel3Open === index2 ? 'auto' : 0}
                                  >
                                    <ul className={styles.lebel3}>
                                      {
                                        item2.list.map((item3, index3) => {
                                          if (!item3.title) return null;
                                          return (
                                            <li className={styles.lebel3Item} key={index3}>
                                              {
                                                item3.outer
                                                  ? <a className={styles.lebel3Link} href={item3.link} target="_blank" rel="noreferrer">{item3.title}</a>
                                                  : <a className={styles.lebel3Link} href={`${path}${item3.link}`}>{item3.title}</a>
                                              }
                                            </li>
                                          );
                                        })
                                      }
                                    </ul>
                                  </AnimateHeight>
                                </li>
                              );
                            }
                            return (
                              <li className={styles.lebel2Item} key={index2}>
                                {
                                  item2.outer
                                    ? <a className={styles.lebel2Link} href={item2.link} target="_blank" rel="noreferrer">{item2.title}</a>
                                    : <a className={styles.lebel2Link} href={`${path}${item2.link}`}>{item2.title}</a>
                                }
                              </li>
                            );
                          })
                        }
                      </ul>
                    </AnimateHeight>
                  </li>
                );
              }
              return (
                <li className={styles.lebel1Item} key={index1}>
                  {
                    item1.outer
                      ? <a className={styles.lebel1Link} href={item1.link} target="_blank" rel="noreferrer">{item1.title}</a>
                      : <a className={styles.lebel1Link} href={`${path}${item1.link}`}>{item1.title}</a>
                  }
                </li>
              );
            })
          }
        </ul>
      </AnimateHeight>
    </div>
  );
};

export default SpSubMenu;
