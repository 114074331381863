import React, { useState, useRef, FC, useEffect } from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import SpSubMenu from './SpSubMenu';
import menu from './head-nav.json';

const HeaderNav: FC<{ path: string}> = ({ path }) => {
  const [open, setOpen] = useState(false);
  const [lebel1, setLebel1] = useState(0);
  const [lebel2, setLebel2] = useState(0);
  const [lebel3, setLebel3] = useState(0);
  const [subHeight, setSubHeight] = useState(0);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleLebel1 = (index) => {
    setLebel1(lebel1 === index ? 0 : index);
    setLebel2(0);
    setLebel3(0);
  };

  const handleLebel2 = (index) => {
    setLebel2(lebel2 === index ? 0 : index);
    setLebel3(0);
  };

  const handleLebel3 = (index) => {
    if (lebel3 === index) {
      setLebel3(0);
    } else {
      setLebel3(index);
    }
  };

  const lebel1Menu = useRef(null);
  const lebel2Menu = useRef(null);
  const lebel3Menu = useRef(null);

  useEffect(() => {
    if (lebel1) {
      setSubHeight(lebel1Menu.current.scrollHeight);
    } else {
      setSubHeight(0);
    }
  }, [lebel1]);

  useEffect(() => {
    if (lebel2) {
      setSubHeight(Math.max(...[lebel1Menu.current.scrollHeight, lebel2Menu.current.scrollHeight]));
    } else if (lebel1) {
      setSubHeight(lebel1Menu.current.scrollHeight);
    } else {
      setSubHeight(0);
    }
  }, [lebel2]);

  useEffect(() => {
    if (lebel3) {
      setSubHeight(
        Math.max(...[
          lebel1Menu.current.scrollHeight,
          lebel2Menu.current.scrollHeight,
          lebel3Menu.current.scrollHeight,
        ]),
      );
    } else if (lebel2) {
      setSubHeight(Math.max(...[lebel1Menu.current.scrollHeight, lebel2Menu.current.scrollHeight]));
    } else if (lebel1) {
      setSubHeight(lebel1Menu.current.scrollHeight);
    } else {
      setSubHeight(0);
    }
  }, [lebel3]);

  const styles = {
    btn: css({
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundColor: '#6889b1',
      cursor: 'pointer',
      border: 0,
      position: 'absolute',
      top: 13,
      right: 10,
      padding: '11px 9px',
      zIndex: 1,

      '@media (min-width: 768px)': {
        display: 'none',
      },
    }),

    btnBar: css({
      height: 3,
      backgroundColor: '#fff',
      width: '100%',
      transition: '0.25s',
    }),

    spBg: css({
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '1000px',
      backgroundColor: 'rgba(0, 0, 0, .45)',
      zIndex: -1,
    }),

    btnBarActive: css({

      '&:nth-of-type(1)': {
        transform: 'translateY(10px) rotate(-315deg)',
      },

      '&:nth-of-type(2)': {
        opacity: 0,
      },

      '&:nth-of-type(3)': {
        transform: 'translateY(-10px) rotate(315deg)',
      },
    }),

    nav: css({
      backgroundColor: '#6889B1',
      transform: 'translateX(100%)',
      transition: '.25s',
      position: 'absolute',
      width: '100%',
      top: '100%',

      '@media (min-width: 768px)': {
        position: 'static',
        transform: 'none !important',
      },
    }),

    navActive: css({
      transform: 'translateX(0)',
    }),

    menuWrap: css({
      maxWidth: 1130,
      margin: '0 auto',
      position: 'relative',

      '@media (min-width: 768px)': {
        padding: '0 15px',
      },
    }),

    menu: css({

      '@media (min-width: 768px)': {
        borderLeft: '1px solid #AFC3DB',
        display: 'flex',
        justifyContent: 'space-between',
      },
    }),

    menuItem: css({

      '@media (min-width: 768px)': {
        width: '20%',
        borderRight: '1px solid #AFC3DB',
      },
    }),

    menuItemBtn: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '15px 5px',
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      width: '100%',
      outline: 0,
      borderBottom: '1px solid #AFC3DB',

      '@media (min-width: 768px)': {
        borderBottom: 0,
      },

      '&:focus': {
        backgroundColor: '#395D88 !important',
      },
    }),

    menuItemBtnActive: css({
      backgroundColor: '#395D88 !important',
    }),

    menuItemIcon: css({
      width: 21,
      margin: '0 5px 0 0',
    }),

    menuItemText: css({
      fontSize: '1.7rem',
      color: '#fff',
    }),

    sub: css({
      display: 'none',

      '@media (min-width: 768px)': {
        position: 'absolute',
        top: '100%',
        left: 15,
        width: 'calc(100% - 30px)',
        backgroundColor: '#395D88',
        transition: 'height .25s',
        overflow: 'hidden',
        display: 'flex',
      },
    }),

    lebel1MenuWrap: css({
      backgroundColor: '#395D88',
      width: '20%',
    }),

    lebel1Menu: css({

    }),

    lebel1MenuItem: css({

    }),

    lebel1MenuLink: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      backgroundColor: 'transparent',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,
      display: 'block',
      textDecoration: 'none',

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel1MenuBtn: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      backgroundColor: 'transparent',
      border: 0,
      width: '100%',
      cursor: 'pointer',
      textAlign: 'left',
      position: 'relative',
      outline: 0,
      lineHeight: 1.4,

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },

      '&:focus': {
        backgroundColor: '#1B3F6B !important',
      },
    }),

    lebel1MenuBtnActive: css({
      backgroundColor: '#1B3F6B !important',
    }),

    lebel2Menu: css({
      width: '20%',
      alignSelf: 'flex-start',
    }),

    lebel2MenuItem: css({

    }),

    lebel2MenuLink: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      display: 'block',
      textDecoration: 'none',
      lineHeight: 1.4,
      position: 'relative',

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },
    }),

    lebel2MenuBtn: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      display: 'block',
      lineHeight: 1.4,
      position: 'relative',
      border: 0,
      width: '100%',
      backgroundColor: 'transparent',
      textAlign: 'left',
      outline: 0,

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },

      '&:after': {
        content: '""',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
        width: 10,
        height: 10,
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%) rotate(-45deg)',
      },

      '&:focus': {
        backgroundColor: '#1B3F6B !important',
      },
    }),

    lebel2MenuBtnActive: css({
      backgroundColor: '#1B3F6B !important',
    }),

    lebel3Menu: css({
      width: '60%',
      alignSelf: 'flex-start',
    }),

    lebel3MenuItem: css({

    }),

    lebel3MenuLink: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      display: 'block',
      textDecoration: 'none',
      lineHeight: 1.4,
      position: 'relative',

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },
    }),

    lebel3MenuBtn: css({
      fontSize: '1.5rem',
      padding: '17px 30px 17px 20px',
      color: '#fff',
      display: 'block',
      lineHeight: 1.4,
      position: 'relative',

      '&:hover': {
        backgroundColor: '#1B3F6B',
      },
    }),
  };

  return (
    <>
      <button className={styles.btn} type="button" onTouchStart={handleOpen}>
        <span className={classNames(styles.btnBar, { [styles.btnBarActive]: open })} />
        <span className={classNames(styles.btnBar, { [styles.btnBarActive]: open })} />
        <span className={classNames(styles.btnBar, { [styles.btnBarActive]: open })} />
      </button>
      {
        open && <div className={styles.spBg} />
      }
      <nav className={classNames(styles.nav, { [styles.navActive]: open })}>
        <div className={styles.menuWrap}>
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <button className={classNames(styles.menuItemBtn, { [styles.menuItemBtnActive]: lebel1 === 1 })} type="button" onClick={() => handleLebel1(1)}>
                <span className={styles.menuItemIcon}>
                  <img src={`${path}images/menu-icon-01.svg`} width="105" height="95" alt="ご来院される方" />
                </span>
                <span className={styles.menuItemText}>ご来院される方へ</span>
              </button>
              <SpSubMenu items={menu[1]} path={path} open={lebel1 === 1} />
            </li>
            <li className={styles.menuItem}>
              <button className={classNames(styles.menuItemBtn, { [styles.menuItemBtnActive]: lebel1 === 2 })} type="button" onClick={() => handleLebel1(2)}>
                <span className={styles.menuItemIcon}>
                  <img src={`${path}images/menu-icon-02.svg`} width="105" height="95" alt="診療科・専門外来" />
                </span>
                <span className={styles.menuItemText}>診療科・専門外来</span>
              </button>
              <SpSubMenu items={menu[2]} path={path} open={lebel1 === 2} />
            </li>
            <li className={styles.menuItem}>
              <button className={classNames(styles.menuItemBtn, { [styles.menuItemBtnActive]: lebel1 === 3 })} type="button" onClick={() => handleLebel1(3)}>
                <span className={styles.menuItemIcon}>
                  <img src={`${path}images/menu-icon-03.svg`} width="105" height="95" alt="当院について" />
                </span>
                <span className={styles.menuItemText}>当院について</span>
              </button>
              <SpSubMenu items={menu[3]} path={path} open={lebel1 === 3} />
            </li>
            <li className={styles.menuItem}>
              <button className={classNames(styles.menuItemBtn, { [styles.menuItemBtnActive]: lebel1 === 4 })} type="button" onClick={() => handleLebel1(4)}>
                <span className={styles.menuItemIcon}>
                  <img src={`${path}images/menu-icon-04.svg`} width="105" height="95" alt="がん治療について" />
                </span>
                <span className={styles.menuItemText}>がん治療について</span>
              </button>
              <SpSubMenu items={menu[4]} path={path} open={lebel1 === 4} />
            </li>
            <li className={styles.menuItem}>
              <button className={classNames(styles.menuItemBtn, { [styles.menuItemBtnActive]: lebel1 === 5 })} type="button" onClick={() => handleLebel1(5)}>
                <span className={styles.menuItemIcon}>
                  <img src={`${path}images/menu-icon-05.svg`} width="105" height="95" alt="医療機関の方へ" />
                </span>
                <span className={styles.menuItemText}>医療機関の方へ</span>
              </button>
              <SpSubMenu items={menu[5]} path={path} open={lebel1 === 5} />
            </li>
          </ul>
          <div className={styles.sub} style={{ height: subHeight }}>
            <div className={styles.lebel1MenuWrap}>
              <ul className={styles.lebel1Menu} ref={lebel1Menu}>
                {
                  menu[lebel1]?.map((item, index) => {
                    if (!item.title) return null;
                    return (
                      <li className={styles.lebel1MenuItem} key={index}>
                        {
                          !item?.list
                            ? item.outer
                              ? <a className={styles.lebel1MenuLink} href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
                              : <a className={styles.lebel1MenuLink} href={`${path}${item.link}`}>{item.title}</a>
                            : index === lebel2
                                ? <button type="button" className={classNames(styles.lebel1MenuBtn, styles.lebel1MenuBtnActive)} onClick={() => handleLebel2(index)}>{item.title}</button>
                                : <button type="button" className={styles.lebel1MenuBtn} onClick={() => handleLebel2(index)}>{item.title}</button>
                            }
                      </li>
                    );
                  })
                }
              </ul>
            </div>
            {
              lebel2
                ? (
                  <ul className={styles.lebel2Menu} ref={lebel2Menu}>
                    {
                      menu[lebel1][lebel2].list.map((item, index) => {
                        if (!item.title) return null;
                        return (
                          <li className={styles.lebel2MenuItem} key={index}>
                            {
                              !item?.list
                                ? item.outer
                                  ? <a className={styles.lebel2MenuLink} href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
                                  : <a className={styles.lebel2MenuLink} href={`${path}${item.link}`}>{item.title}</a>
                                : index === lebel3
                                  ? <button type="button" className={classNames(styles.lebel2MenuBtn, styles.lebel2MenuBtnActive)} onClick={() => handleLebel3(index)}>{item.title}</button>
                                  : <button type="button" className={styles.lebel2MenuBtn} onClick={() => handleLebel3(index)}>{item.title}</button>
                            }
                          </li>
                        );
                      })
                    }
                  </ul>
                )
                : null
            }
            {
              lebel3
                ? (
                  <ul className={styles.lebel3Menu} ref={lebel3Menu}>
                    {
                      menu[lebel1][lebel2]?.list[lebel3]?.list?.map((item, index) => (
                        <li className={styles.lebel3MenuItem} key={index}>
                          {
                            item.outer
                              ? <a className={styles.lebel3MenuLink} href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
                              : <a className={styles.lebel3MenuLink} href={`${path}${item.link}`}>{item.title}</a>
                          }
                        </li>
                      ))
                    }
                  </ul>
                )
                : null
            }
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderNav;
