import React, { FC, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import className from 'classnames';
import 'slick-carousel/slick/slick.css';
import { css } from '@emotion/css';
import { Global } from '@emotion/react';
import 'objectFitPolyfill';

const IndexCarousel: FC = () => {
  const settings: Settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipe: false,
    // autoplay: true,
    centerMode: true,
    centerPadding: '0px',
  };

  const list = JSON.parse(document.getElementById('introCarouselJson').textContent);

  useEffect(() => {
    objectFitPolyfill();
  }, []);

  const globalStyle = {
    '.slick-arrow': {
      position: 'absolute',
      zIndex: 5,
      textIndent: -9999,
      width: 25,
      height: 36,
      backgroundColor: '#74B3FF',
      border: 0,
      cursor: 'pointer',
      boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
      top: '22%',
    },

    '.slick-prev': {
      left: -10,

      '&:after': {
        content: '""',
        position: 'absolute',
        width: 10,
        height: 10,
        top: '50%',
        left: '50%',
        borderLeft: '3px solid #fff',
        borderTop: '3px solid #fff',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
      },
    },

    '.slick-next': {
      right: -10,

      '&:after': {
        content: '""',
        position: 'absolute',
        width: 10,
        height: 10,
        top: '50%',
        left: '50%',
        borderRight: '3px solid #fff',
        borderTop: '3px solid #fff',
        transform: 'translate(-50%, -50%) rotate(45deg)',
      },
    },

    '.slick-dots': {
      display: 'flex !important',
      justifyContent: 'center',
      margin: '10px 0 0',

      '& li': {
        margin: '0 5px',

        '& button': {
          textIndent: -9999,
          width: 10,
          height: 10,
          backgroundColor: '#A4A4A4',
          border: 0,
          borderRadius: '50%',
          cursor: 'pointer',
          outline: 0,
        },
      },
    },

    '.slick-active': {
      '& button': {
        backgroundColor: '#333333 !important',
      },
    },
  };

  const styles = {
    slide: css({
      // padding: '0 10px',
    }),

    slideBox: css({
      display: 'flex',
      justifyContent: 'space-between',
    }),

    slideBodyWrap: css({
      width: 'calc(50% - 5px)',

      '@media (min-width: 768px)': {
        width: 'calc(50% - 10px)',
      },
    }),

    slideBody: css({
      position: 'relative',
      paddingTop: '18.72659%',

      // '@media (min-width: 768px)': {
      //   paddingTop: '18.72659%',
      // },
    }),

    textSlide: css({
      position: 'absolute',
      top: 0,
      left: 0,
      padding: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      width: '100%',
      height: '100%',
    }),

    textSlide1: css({
      backgroundColor: '#DE0000',
    }),

    textSlide2: css({
      backgroundColor: '#6889B1',
    }),

    textSlide3: css({
      backgroundColor: '#2B2B92',
    }),

    textSlide4: css({
      backgroundColor: '#42CFCA',
    }),

    textSlideText: css({
      color: '#fff',
      fontSize: '10px',
      fontWeight: 'bold',
      lineHeight: '1.1',

      '@media (min-width: 768px)': {
        fontSize: '2.3rem',
      },
    }),

    picSlide: css({
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      '& img': {
        objectFit: 'cover',
        objectPosition: 'center center',
        width: '100%',
        height: '100%',

        '@media (min-width: 768px)': {
          objectFit: 'cover',
        },
      },
    }),
  };

  return (
    <>
      <Global styles={globalStyle} />
      <Slider {...settings}>
        {
          list.map((items, index1) => (
            <div className={styles.slide} key={index1}>
              <div className={styles.slideBox}>
                {
                  items.map((item, index2) => (
                    item.pic
                      ? (
                        <div className={styles.slideBodyWrap}>
                          <div className={styles.slideBody}>
                            <a href={item.link} className={styles.picSlide} target="_blank" rel="noreferrer" key={index2}>
                              <img data-object-fit src={item.pic} alt="" />
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.slideBodyWrap}>
                          <div className={styles.slideBody}>
                            <a
                              href={item.link}
                              className={
                                className(
                                  styles.textSlide,
                                  { [styles.textSlide1]: item.color === 1 },
                                  { [styles.textSlide2]: item.color === 2 },
                                  { [styles.textSlide3]: item.color === 3 },
                                  { [styles.textSlide4]: item.color === 4 },
                                )
                              }
                              target="_blank"
                              rel="noreferrer"
                              key={index2}
                            >
                              <span className={styles.textSlideText}>{item.text}</span>
                            </a>
                          </div>
                        </div>
                      )
                  ))
                }
              </div>
            </div>
          ))
        }
      </Slider>
    </>
  );
};

export default IndexCarousel;
