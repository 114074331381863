import React, { FC } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { css } from '@emotion/css';

const IndexCarousel: FC = () => {
  const settings: Settings = {
    arrows: false,
    infinite: true,
    speed: 1200,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    variableWidth: true,

    responsive: [{
      breakpoint: 1100,
      settings: {
        centerPadding: '10px',
        variableWidth: false,
      },
    }],
  };

  const styles = {
    slide: css({
      width: 1010,
      padding: '0 5px',

      '@media (min-width: 768px)': {
        padding: '0 5px',
      },
    }),
  };

  return (
    <Slider {...settings}>
      <div className={styles.slide}>
        <img src="images/index-slide-01.jpg" alt="" />
      </div>
      <div className={styles.slide}>
        <img src="images/index-slide-02.jpg" alt="" />
      </div>
      <div className={styles.slide}>
        <img src="images/index-slide-03.jpg" alt="" />
      </div>
    </Slider>
  );
};

export default IndexCarousel;
