import React, { useState, FC } from 'react';
import className from 'classnames';
import { css } from '@emotion/css';

const ParkingMap: FC = () => {
  const [map, setMap] = useState(1);

  const handleChoice = (num) => {
    setMap(num);
  };

  const styles = {
    map: css({
      margin: '0 0 10px',
    }),

    btnList: css({
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    }),

    btnListItem: css({
      width: 'calc(100% / 3 - ((5px * 2) / 3))',
      margin: '0 0 5px',

      '@media (min-width: 768px)': {
        margin: 0,
        width: 'calc(100% / 6 - ((5px * 5) / 6))',
      },
    }),

    btn: css({
      backgroundColor: '#6889B1',
      border: 0,
      color: '#fff',
      display: 'block',
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      fontSize: 10,

      '@media (min-width: 768px)': {
        fontSize: 15,
      },
    }),

    btnActive: css({
      backgroundColor: '#002397 !important',
    }),
  };

  return (
    <>
      <div className={styles.map}>
        <img src={`images/index-map-0${map}.gif`} alt="" />
      </div>
      <ul className={styles.btnList}>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 1 })} onClick={() => handleChoice(1)}>全ての駐車場</button>
        </li>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 2 })} onClick={() => handleChoice(2)}>初めての外来</button>
        </li>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 3 })} onClick={() => handleChoice(3)}>救急外来</button>
        </li>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 4 })} onClick={() => handleChoice(4)}>お見舞いの方</button>
        </li>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 5 })} onClick={() => handleChoice(5)}>健康診断の方</button>
        </li>
        <li className={styles.btnListItem}>
          <button type="button" className={className(styles.btn, { [styles.btnActive]: map === 6 })} onClick={() => handleChoice(6)}>市民講座参加の方</button>
        </li>
      </ul>
    </>
  );
};

export default ParkingMap;
