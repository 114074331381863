import React from 'react';
import ReactDom from 'react-dom';

import HeaderNav from './components/HeaderNav';
import IndexCarousel from './components/IndexCarousel';
import IntroCarousel from './components/IntroCarousel';
import ParkingMap from './components/ParkingMap';

const { path } = document.body.dataset;

ReactDom.render(<HeaderNav path={path} />, document.getElementById('headerNav'));
ReactDom.render(<IndexCarousel />, document.getElementById('indexCarousel'));
ReactDom.render(<IntroCarousel />, document.getElementById('introCarousel'));
ReactDom.render(<ParkingMap />, document.getElementById('parkingMap'));
